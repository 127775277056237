<template>
  <div>
    <b-modal
          :ref="`my-modal`"
          centered
          ok-only
          ok-title="حسنا"
          cancel-variant="outline-secondary"
          title-tag="div"
        >
<b-row>
  <b-col md="3" class="mb-md-0 mb-2">
<h3>العنوان: </h3>
<p>{{data.title}}</p>
  </b-col>
  <b-col md="3" class="mb-md-0 mb-2" v-if="data.department">
<h3>الأقسام: </h3>
<div v-for="item in data.departments">{{item.name}}  <br /></div>
  </b-col>

</b-row>
<b-row>
  <b-col md="12"  >
<h3>الوصف: </h3>
<p >{{data.description}}</p>
  </b-col>

</b-row>
          
        </b-modal>

        <b-modal
          :ref="`my-modal-emp`"
          centered
          ok-only
          ok-title="حسنا"
          cancel-variant="outline-secondary"
          title-tag="div"
        >
          <b-table responsive="sm" :items="EmployeeData" :fields="fields" >
            <template #cell(read_by_user)="data">
              {{data.item.read_by_user.first_name + " " + data.item.read_by_user.last_name}}
        </template>
          </b-table>

          <!-- </b-card-code> -->
        </b-modal>

    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="  إضافة سياسة">
          <b-row>

            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="title"
                rules="required"
              >
                <b-form-group label-for="title">
                  <label> العنوان</label>
                  <b-form-input
                    v-model="title"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>

            <b-col
              md="4"
              xl="4"
            >
              <validation-provider
                #default="{ errors }"
                name="type"
                rules="required"
              >
         
                <b-form-group label-for="type">
                  <label> النوع</label>
                  <v-select
                  v-model="type"
                  label="name"
                  :options="optionsType"
                  :reduce="(val) => val.id"
                />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="4"
              xl="4"
              v-if="showDepartment"
            >
              <validation-provider
                #default="{ errors }"
                name="department"
                rules="required"
              >
         
                <b-form-group label-for="type">
                  <label> القسم</label>
                  <v-select
                  v-model="department"
                  :options="departmentOptions"
                  label="name"
                  :reduce="(val) => val.id"
                  multiple
                  :clearable="true"
                />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            <b-col
              md="12"
              xl="12"
            >
              <validation-provider
                #default="{ errors }"
                name="description"
                rules=""
              >
                <b-form-group label-for="subject">
                  <label> الوصف</label>
                  <b-form-textarea
                    v-model="description"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
            
          
         
          </b-row>
          <!-- <b-row>
            <b-col cols="12">
          <b-form-group label="المحتوى" label-for="blog-content" class="mb-2">
            <quill-editor id="blog-content" v-model="description" :options="snowOption" />
          </b-form-group>
        </b-col>
          </b-row> -->
          
        
        
       
     
     
            
    
            <b-row>
              <b-col
                md="1"
                xl="3"
              >
                <b-button
                  variant="purple"
                  @click="addPolicy"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle"> إضافة </span>
                </b-button>
              </b-col>
            </b-row>
          
        </b-card-code>
      </b-form>
    </validation-observer>

    <b-card-code title=" السياسات">
      <b-card no-body>
      <b-card-header class="p-50" />
      <b-card-body class="p-50" >
        <b-row>
          <b-col
            cols="3"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>
              النوع 
            </label>
    <v-select
           v-model="typeFilter"
          label="name"
          :options="optionsType"
          :reduce="(val) => val.id"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              
        
            />

          </b-col>
          <b-col
            cols="3"
            md="3"
            class="mb-md-0 mb-2"
          >
          </b-col>

          <b-col
          cols="6" md="6"
            class="mb-md-0 mb-2"
          >
            <validation-provider
              name="search"
            >
              <label>بحث</label>
              <b-form-input
                v-model="search"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"

                class="w-100"
              />

            </validation-provider>
          </b-col>

   </b-row>
      </b-card-body>
    </b-card>

      <hr />
<!-- <b-row>
  <b-col
            cols="6"
            md="3"
            class="mb-md-0 mb-2"
          >
            <label>
              النوع 
            </label>
    <v-select
           v-model="typeFilter"
          label="name"
          :options="optionsType"
          :reduce="(val) => val.id"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              
        
            />

          </b-col>
   </b-row> -->


      <!-- table -->
      <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2 row">
        
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>

        </b-col>

        <b-col
          cols="6"
          md="6"
          class=""
        >
          <label> {{ total }}      عدد السياسات</label>
          <label />

        </b-col>

      </div>

      <b-table
      ref="refListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        :current-page="currentPage"
        :per-page="perPage"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
       <template #cell(reading_by)="data">
        <feather-icon icon="EyeIcon" class="mr-50" @click="showEmpModal(data.item.reading_by)" />
        </template>
      <template

          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          <b-dropdown-item-button
                  @click="
                    $router.push({
                      name: 'edit-policy',
                      params: { id: data.item.id },
                    })
                  "
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>تعديل</span>
                </b-dropdown-item-button>
                          <b-dropdown-item-button
                          @click="showModal(data.item)"
                  
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>عرض</span>
                </b-dropdown-item-button>
                
                <b-dropdown-item-button @click="deletePolicy(data.item.id)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>حذف</span>
                </b-dropdown-item-button>
          
              </b-dropdown>
        </template>

         
            </b-table>

          

       
           </b-card>
           <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

    </b-card-code>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import useList from "./List";
import {
  BRow,

  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,

  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
  BCard,
} from 'bootstrap-vue'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { quillEditor } from "vue-quill-editor";
export default {
  name: 'AddSession',
  components: {
    flatPickr,
    BDropdown,
BCard,
    ToastificationContent,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,

    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,

    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,
    BCol,
    BPagination,
  BTable,
  BCardHeader, 
  BCardBody,
  },

  setup(){
  const {
    search,
    typeFilter,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      fetchData,
      

      // Extra Filters
    } = useList()

    
    
    return {
      fetchData,
      search,
      filterOn: [],
      searchTerm: "",
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      typeFilter
    }

},

  data() {
    return {
title:'',
type:'',
description:'',
department:[],
optionsType:[
{id:2, name:"عامة"},
{id:1, name:"خاصة"},
],
departmentOptions:[],
data:{},
EmployeeData:[],
showDepartment: true,
fields: [
        { key: "read_by_user", label: "الموظف " },
        { key: "department.name", label: "القسم" },
        { key: "date", label: "تاريخ القراءة" },
      ],
    };

   
  },

  watch: {
    type(newVal, oldVal) {
      if (newVal === 1) {
        this.showDepartment = true; // Show department field if type is 1
      } else {
        this.showDepartment = false; // Hide department field for any other type
      }
    }
  },

  created() {
this.getDepartment()
  },

  methods: {
    showModal(data) {
      //console.log(data);
      this.data = data;
      this.$refs["my-modal"].show();
    },
    showEmpModal(data) {
    //  console.log(data);
      this.EmployeeData = data;
     // console.log(this.EmployeeData);
      this.$refs["my-modal-emp"].show();
    },
    getDepartment() {
      this.departmentOptions = []
      this.$http.get('/api/v1/departments').then(res => {
        // console.log('itemres',res);
        this.departmentOptions = res.data.data

        // console.log(this.optionitem);
      })
    },

    addPolicy() {
      const data = {
        title: this.title,
        description: this.description,
        type: this.type,
        department: this.department,
      }
     //console.log(data);
      const url = `/api/v1/policy`
      this.$http
        .post(url,data)
        .then(res => {
          this.$swal({
            title: 'تم إضافة سياسة  بنجاح',

            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.refetchData()
         this.title = ''
       this.description= ''
       this.type=''
       this.department=[]

        })
        .catch(error => {
          // console.log(error.response.data.message);
          this.$swal({
            title: `${error.response.data.message}`,

            icon: 'success',
            confirmButtonText: 'موافق',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
   },

   deletePolicy(id) {
      this.$swal({
        icon: "warning",
        title: "هل انت متأكد من الحذف",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      }).then((result) => {
        if (result.value) {
          let url = `/api/v1/policy/${id}`;
          this.$http.delete(url).then((res) => {
            //console.log(res);
            this.refetchData();
            this.$swal({
        icon: "warning",
        title: "تم الحذف  بنجاح",
        confirmButtonText: "موافق",
        cancelButtonText: "الغاء",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "لا",
        },
      })
            
          });
        }
      });
    },

   },
}
</script>

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
